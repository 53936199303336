// translations.js
export const translations = {
  Greek: {
    home: 'ΑΡΧΙΚΗ',
    aboutUs: 'ΠΟΙΟΙ ΕΙΜΑΣΤΕ',
    instructions: 'ΟΔΗΓΙΕΣ',
    forLawyers: 'ΓΙΑ ΔΙΚΗΓΟΡΟΥΣ',
    profile: 'Προφίλ',
    authorization: 'Εξουσιοδότηση',
    registration: 'Εγγραφή',
    mainText: 'Η ΑΝΑΖΗΤΗΣΗ ΤΟΥ ΔΙΚΑΙΟΥ',
    mainSubtext: 'Η εμπειρία μας, η γαλήνη σας. 24/7 κοντά σε εσάς και τα προβλήματα σας.',
    mainServicesSubtext:`Μην αφήνετε τα νομικά σας προβλήματα να σας ταλαιπωρούν. Επικοινωνήστε μαζί μας 
    σήμερα και επιτρέψτε μας να σας βοηθήσουμε να βρείτε τη λύση που χρειάζεστε.`,
    mainServicesText: 'Οι ΥπηρεσΙες μας',
    onlineLawyerChat: 'Online Δικηγόρος Chat',
    booking24: 'Κράτηση 24',
    password: 'Κωδικός',
    login: 'Είσοδος',
    welcome: 'Καλώς ήρθατε στην DIKIGOROS ONLINE - Νομικές Υπηρεσίες Παντού και Πάντα . 24/7 κοντά σε εσάς και τα προβλήματα σας .',
    introText: 'Στην DIKIGOROS ONLINE, φέρνουμε την επανάσταση στην παροχή νομικών υπηρεσιών, προσφέροντας εύκολη και άμεση πρόσβαση σε εξειδικευμένους δικηγόρους μέσω διαδικτύου. Είμαστε αφοσιωμένοι στο να παρέχουμε στους πολίτες της χώρας μας αξιόπιστη νομική υποστήριξη, οποιαδήποτε στιγμή την χρειαστούν. Προσφέρουμε την άμεση και τεκμηριωμένη απάντηση που ζητάτε, ακόμη και πριν αναθεσετε την ΥΠΟΘΕΣΗ σε ένα απο τους εξειδικευμένους δικηγόρους μας, μέσω της καινοτόμου διαδικτυακής υπηρεσίας μας Chatbot.',
    ourServices: 'Οι Υπηρεσίες μας:',
    legalAdvice: 'Νομικές Συμβουλές 24/7: Οι δικηγόροι μας είναι διαθέσιμοι όλο το 24ωρο για να απαντήσουν σε όλα σας τα νομικά ερωτήματα, από επείγοντα ζητήματα μέχρι καθημερινές νομικές ανησυχίες.',
    familyLaw: 'Οικογενειακό Δίκαιο: Σας παρέχουμε υποστήριξη σε θέματα διαζυγίων, επιμέλειας παιδιών, διατροφής και άλλων οικογενειακών υποθέσεων.',
    criminalLaw: 'Ποινικό Δίκαιο: Εξειδικευμένη υπεράσπιση και συμβουλευτική σε ποινικές υποθέσεις, διασφαλίζοντας τα δικαιώματά σας.',
    laborLaw: 'Εργατικό Δίκαιο: Βοηθάμε σε ζητήματα εργασιακών διαφορών, απολύσεων, αμοιβών και άλλων εργασιακών δικαιωμάτων.',
    civilLaw: 'Αστικό Δίκαιο: Καλύπτουμε ένα ευρύ φάσμα αστικών υποθέσεων, μικρής , μέσης και διαβαθμισμένης δυσκολίας .',

    personalDataLaw: 'ΔΙΚΑΙΟ ΠΡΟΣΩΠΙΚΩΝ ΔΕΔΟΜΕΝΩΝ',
    personalDataLawText: 'Η προσβολή της ιδιωτικότητας του ατόμου στις μέρες μας είναι η μάστιγα της εποχής. Υπάρχει προστασία αρκεί να ξέρεις να την βρεις.',
    personalRehabilitationLaw: 'ΔΙΚΑΙΟ ΕΞΥΓΙΑΝΣΗΣ ΦΥΣΙΚΩΝ ΠΡΟΣΩΠΩΝ ΚΑΙ ΕΠΙΧΕΙΡΗΣΕΩΝ :',
    personalRehabilitationLawText: 'Η υπερχρέωση αποτελεί το νούμερο ένα ζήτημα και προκαλεί αβεβαιότητα. Με σωστό πλάνο και κατεύθυνση όμως πάντα υπάρχει και η άλλη επιλογή.',
    taxLaw: 'ΦΟΡΟΛΟΓΙΚΟ ΔΙΚΑΙΟ :',
    taxLawText: 'Η σχέση κράτους πολίτη στην Ελλάδα είναι όπως όλοι ομολογούν εχθρική. Μόνο με εξειδίκευση και ορθή κατεύθυνση αποφεύγεις δυσβάσταχτα πρόστιμα και προσαυξήσεις από τις φορολογικές αρχές.',
    realEstateLaw: 'ΔΙΚΑΙΟ ΑΚΙΝΗΤΩΝ :',
    realEstateLawText: 'Μισθώσεις, μεταβιβάσεις, πολεοδομικά θέματα, διανομή περιουσίας μεταξύ κληρονόμων μερικά από τα καθημερινά ζητήματα που απασχολούν τον ιδιοκτήτη ακινήτου στην Ελλάδα. Τρόποι και πλάνο ορθής και συνετής διαχείρισης περιουσίας η μόνη επιλογή.',
    enforcementLaw: 'ΔΙΚΑΙΟ ΑΝΑΓΚΑΣΤΙΚΗΣ ΕΚΤΕΛΕΣΗΣ ΚΑΙ ΑΠΟΤΡΟΠΗΣ ΠΛΕΙΣΤΗΡΙΑΣΜΩΝ.',
    enforcementLawText: 'Η έγκαιρη και εντός των αυστηρών χρονικών ορίων ανακοπή των διαδικασιών πλειστηριασμού αποτελεί το καλύτερο διαπραγματευτικό όπλο για μελλοντικές συζητήσεις σας με κάθε πιστωτή σας.',


    expropriationLaw: 'ΔΙΚΑΙΟ ΑΝΑΓΚΑΣΤΙΚΩΝ ΑΠΑΛΛΟΤΡΙΩΣΕΩΝ.',
    expropriationLawText: 'Έχετε χάσει την περιουσία σας από το κράτος και δεν έχετε αποζημιωθεί; Αναλαμβάνουμε όλη τη διαδικασία πληρωμής σας ή ακόμη και της αποδέσμευσης της περιουσίας σας για να την αξιοποιήσετε όπως εσείς επιθυμείτε.',
    trafficAccidentsLaw: 'ΤΡΟΧΑΙΑ ΑΤΥΧΗΜΑΤΑ :',
    trafficAccidentsLawText: 'Μια δύσκολη περίπτωση η έμπλοκη σας με τις ασφαλιστικές εταιρείες. Εισπράξτε την αποζημίωση που πραγματικά δικαιούστε, χωρίς περικοπές ή τα ψιλά γράμματα των ασφαλιστηρίων συμβολαίων.',
    medicalMalpractice: 'ΙΑΤΡΙΚΑ ΛΑΘΗ',
    medicalMalpracticeText: 'και Παραλείψεις του συστήματος υγείας.',
    subsidyReturns: 'ΕΠΙΣΤΡΟΦΕΣ ΕΠΙΔΟΤΗΣΕΩΝ ΑΠΟ ΕΠΕΝΔΥΤΙΚΟ ΝΟΜΟ ΚΑΙ ΕΣΠΑ.',
    subsidyReturnsText: 'Συχνά το κράτος για μικρές παρατυπίες ζητά επιστροφή του συνόλου της επένδυσης. Αντιμετωπίζουμε την όλη κατάσταση τόσο ενδοδιοικητικά όσο και με προσφυγή στα αρμόδια δικαστήρια προς αποτροπή αυτής της αδικίας εις βάρος σας.',
    chatbotServices: 'Υπηρεσίες μέσω Chatbot:',
    chatbotServicesText: 'Το έξυπνο chatbot μας είναι διαθέσιμο να απαντήσει σε όλες τις νομικές σας ερωτήσεις σε οποιαδήποτε γλώσσα. Απλά πληκτρολογήστε το μήνυμά σας στη γλώσσα που προτιμάτε. Η υπηρεσία αυτή είναι επι πληρωμή αντί συμβολικού τιμήματος για την κάλυψη του κόστους συντήρησης μιας τεράστιας βάσης νομικών δεδομένων.',
    onlineLawyerAppointment: 'Online Ραντεβού με Δικηγόρο:',
    onlineLawyerAppointmentText: 'Κλείστε το ραντεβού σας με εξειδικευμένο δικηγόρο μέσα στις επόμενες 24 ώρες και λάβετε την υποστήριξη που χρειάζεστε. Οι υπηρεσίες μας είναι επί πληρωμή.',

    howWeCanHelp: 'Πώς μπορούμε να σας βοηθήσουμε:',
    easyAccess: 'Εύκολη Πρόσβαση:',
    easyAccessText: 'Με την διαδικτυακή μας πλατφόρμα, μπορείτε να κλείσετε ραντεβού, να λάβετε συμβουλές και να συνομιλήσετε με δικηγόρο εύκολα και γρήγορα.',
    immediateResponse: 'Άμεση Ανταπόκριση:',
    immediateResponseText: 'Είμαστε εδώ για να σας προσφέρουμε άμεση βοήθεια όποτε την χρειάζεστε, χωρίς καθυστερήσεις.',
    expertiseExperience: 'Εξειδίκευση και Εμπειρία:',
    expertiseExperienceText: 'Η ομάδα μας αποτελείται από έμπειρους δικηγόρους με εξειδίκευση σε διάφορους τομείς του δικαίου, εξασφαλίζοντας την καλύτερη δυνατή υποστήριξη.',
    affordableServices: 'Προσιτές Υπηρεσίες:',
    affordableServicesText: 'Προσφέρουμε οικονομικά προσιτές λύσεις για να εξασφαλίσουμε ότι η νομική υποστήριξη είναι προσβάσιμη σε όλους τους πολίτες 24/7.',
    contactUs: 'Επικοινωνήστε μαζί μας:',
    contactUsText: 'Μην αφήνετε τα νομικά σας προβλήματα να σας ταλαιπωρούν. Επικοινωνήστε μαζί μας σήμερα και επιτρέψτε μας να σας βοηθήσουμε να βρείτε τη λύση που χρειάζεστε. Είμαστε εδώ για εσάς, κάθε στιγμή της ημέρας, κάθε ημέρα της εβδομάδας.',
    dikigorosOnline: 'DIKIGOROS ONLINE - Νομικές Υπηρεσίες Παντού και Πάντα',

    instructionsTitle: 'Οδηγίες',
    instructionsText1: 'Στην ιστοσελίδα μας μπορείτε να χρησιμοποιήσετε την online συμβουλή με τον εικονικό βοηθό. Για αυτό, στην αρχική σελίδα κάντε κλικ στο κουμπί "Online Δικηγόρος Chat". Θα χρειαστεί να πληρώσετε μια συνδρομή, η οποία θα ισχύει για 24 ώρες.',
    instructionsText2: 'Αν έχετε ένα δύσκολο ζήτημα, μπορείτε να χρησιμοποιήσετε το κουμπί "Κράτηση 24". Οι δικηγόροι μας θα το εξετάσουν και θα επικοινωνήσουν μαζί σας εντός 24 ωρών.',
    onlineChatText1: 'Online ',
    onlineChatText1_1: 'ΔικηγOρος Chat',
    onlineChatText2: 'Στην ιστοσελίδα μας μπορείτε να χρησιμοποιήσετε την online συμβουλή με τον εικονικό βοηθό. Για αυτό κλικ στο κουμπί "Online Δικηγόρος Chat". Θα χρειαστεί να πληρώσετε μια συνδρομή, η οποία θα ισχύει για 24 ώρες.'

  },
  Russian: {
    home: 'Главная',
    aboutUs: 'Кто мы',
    instructions: 'Инструкции',
    forLawyers: 'Для юристов',
    profile: 'Профиль',
    authorization: 'Авторизация',
    registration: 'Регистрация',
    mainText: 'Поиск справедливости',
    mainSubtext: 'Наш опыт, ваше спокойствие',
    mainServicesSubtext:`Не позволяйте вашим юридическим проблемам вас беспокоить. 
    Свяжитесь с нами сегодня и позвольте нам помочь вам найти нужное решение.`,
    mainServicesText: 'Наши услуги',
    onlineLawyerChat: 'Онлайн чат с адвокатом',
    booking24: 'Бронирование 24',
    password: 'Пароль',
    login: 'Войти',
    welcome: 'Добро пожаловать в DIKIGOROS ONLINE - Юридические услуги Везде и Всегда. 24/7 рядом с вами и ваши проблемы.',
    introText: 'В DIKIGOROS ONLINE мы внедряем революцию в предоставлении юридических услуг, обеспечивая легкий и мгновенный доступ к квалифицированным юристам через интернет. Мы посвящены предоставлению надежной юридической поддержки нашим гражданам в любое время, когда это им необходимо. Мы предлагаем немедленный и обоснованный ответ, который вам нужен, даже до того, как вы поручите ваше дело одному из наших специализированных адвокатов, с помощью нашей инновационной интернет-службы Chatbot.',
    ourServices: 'Наши Услуги:',
    legalAdvice: 'Юридические консультации 24/7: Наши адвокаты доступны круглосуточно для ответов на все ваши юридические вопросы, от срочных вопросов до повседневных юридических проблем.',
    familyLaw: 'Семейное право: Мы предоставляем поддержку по вопросам развода, опеки над детьми, алиментов и других семейных дел.',
    criminalLaw: 'Уголовное право: Специализированная защита и консультации по уголовным делам, обеспечивая защиту ваших прав.',
    laborLaw: 'Трудовое право: Мы помогаем в вопросах трудовых споров, увольнений, заработной платы и других трудовых прав.',
    civilLaw: 'Гражданское право: Охватываем широкий спектр гражданских дел различной сложности.',

    personalDataLaw: 'Закон о защите персональных данных',
    personalDataLawText: 'Нарушение конфиденциальности в наши дни является бичом нашего времени. Защита существует, если знаешь, где её найти.',
    personalRehabilitationLaw: 'Закон о реабилитации физических лиц и предприятий:',
    personalRehabilitationLawText: 'Сверхзадолженность является проблемой номер один и вызывает неуверенность. Однако, при правильном плане и руководстве всегда есть другой вариант.',
    taxLaw: 'Налоговое право:',
    taxLawText: 'Отношения между государством и гражданами в Греции, как все признают, враждебные. Только с помощью специализации и правильного руководства можно избежать непосильных штрафов и надбавок от налоговых органов.',
    realEstateLaw: 'Закон о недвижимости:',
    realEstateLawText: 'Аренда, передача, вопросы градостроительства, распределение имущества между наследниками — это некоторые из повседневных вопросов, которые волнуют владельца недвижимости в Греции. Правильное и разумное управление имуществом — единственный вариант.',
    enforcementLaw: 'Закон о принудительном исполнении и предотвращении аукционов.',
    enforcementLawText: 'Своевременное и в строгие сроки обжалование процедур аукциона является лучшим переговорным оружием для ваших будущих переговоров с каждым кредитором.',

    expropriationLaw: 'Закон об обязательном изъятии.',
    expropriationLawText: 'Вы потеряли своё имущество из-за государства и не получили компенсацию? Мы берем на себя весь процесс выплаты или даже освобождения вашего имущества, чтобы вы могли использовать его так, как хотите.',
    trafficAccidentsLaw: 'Дорожные происшествия:',
    trafficAccidentsLawText: 'Взаимодействие с страховыми компаниями — сложное дело. Получите компенсацию, на которую вы действительно имеете право, без сокращений или мелкого шрифта в страховых полисах.',
    medicalMalpractice: 'Медицинские ошибки',
    medicalMalpracticeText: 'и упущения системы здравоохранения.',
    subsidyReturns: 'Возврат субсидий по инвестиционному закону и ЕСФ:',
    subsidyReturnsText: 'Государство часто требует возврата всей инвестиции за незначительные нарушения. Мы решаем этот вопрос как в административном порядке, так и с помощью обращений в компетентные суды, чтобы предотвратить эту несправедливость в отношении вас.',
    chatbotServices: 'Услуги через Chatbot:',
    chatbotServicesText: 'Наш умный чат-бот готов ответить на все ваши юридические вопросы на любом языке. Просто введите ваше сообщение на предпочитаемом языке. Эта услуга платная, за символическую плату для покрытия затрат на обслуживание огромной базы данных юридической информации.',
    onlineLawyerAppointment: 'Онлайн встреча с адвокатом:',
    onlineLawyerAppointmentText: 'Запишитесь на прием к квалифицированному адвокату в течение следующих 24 часов и получите необходимую поддержку. Наши услуги платные.',

    howWeCanHelp: 'Как мы можем вам помочь:',
    easyAccess: 'Легкий доступ:',
    easyAccessText: 'С помощью нашей онлайн-платформы вы можете легко и быстро записаться на прием, получить консультацию и поговорить с адвокатом.',
    immediateResponse: 'Немедленный отклик:',
    immediateResponseText: 'Мы здесь, чтобы предложить вам немедленную помощь, когда она вам нужна, без задержек.',
    expertiseExperience: 'Экспертиза и опыт:',
    expertiseExperienceText: 'Наша команда состоит из опытных адвокатов, специализирующихся в различных областях права, обеспечивая наилучшую поддержку.',
    affordableServices: 'Доступные услуги:',
    affordableServicesText: 'Мы предлагаем доступные решения, чтобы обеспечить юридическую поддержку всем гражданам 24/7.',
    contactUs: 'Свяжитесь с нами:',
    contactUsText: 'Не позволяйте юридическим проблемам мучить вас. Свяжитесь с нами сегодня, и позвольте нам помочь вам найти нужное решение. Мы здесь для вас в любое время дня, каждый день недели.',
    dikigorosOnline: 'DIKIGOROS ONLINE - Юридические услуги везде и всегда',

    instructionsTitle: 'Инструкции',
    instructionsText1: 'На нашем сайте вы можете воспользоваться онлайн консультацией с виртуальным помощником. Для этого на главной странице нажмите кнопку "Онлайн чат с адвокатом". Необходимо будет оплатить подписку, которая будет действовать 24 часа.',
    instructionsText2: 'Если у вас сложный вопрос, вы можете воспользоваться кнопкой "Booking 24". Наши адвокаты рассмотрят его и в течение суток свяжутся с вами.',
    onlineChatText1: 'Online ',
    onlineChatText1_1: 'ΔικηγOρος Chat',
    onlineChatText2: 'Στην ιστοσελίδα μας μπορείτε να χρησιμοποιήσετε την online συμβουλή με τον εικονικό βοηθό. Για αυτό κλικ στο κουμπί "Online Δικηγόρος Chat". Θα χρειαστεί να πληρώσετε μια συνδρομή, η οποία θα ισχύει για 24 ώρες.'
  },
  English: {
    home: 'Home',
    aboutUs: 'About Us',
    instructions: 'Instructions',
    forLawyers: 'For Lawyers',
    profile: 'Profile',
    authorization: 'Authorization',
    registration: 'Registration',
    mainText: 'The pursuit of justice',
    mainSubtext: 'Our experience, your peace of mind',
    mainServicesSubtext:`Μην αφήνετε τα νομικά σας προβλήματα να σας ταλαιπωρούν. Επικοινωνήστε μαζί μας 
    σήμερα και επιτρέψτε μας να σας βοηθήσουμε να βρείτε τη λύση που χρειάζεστε.`,
    mainServicesText: 'Services',
    onlineLawyerChat: 'Online lawyer chat',
    booking24: 'Booking 24',
    password: 'Password',
    login: 'Login',
    welcome: 'Welcome to DIKIGOROS ONLINE - Legal Services Everywhere and Always. 24/7 close to you and your problems.',
    introText: 'At DIKIGOROS ONLINE, we revolutionize the provision of legal services, offering easy and immediate access to specialized lawyers online. We are dedicated to providing our citizens with reliable legal support whenever they need it. We offer the immediate and well-documented response you seek, even before you assign your CASE to one of our specialized lawyers, through our innovative online service Chatbot.',
    ourServices: 'Our Services:',
    legalAdvice: 'Legal Advice 24/7: Our lawyers are available around the clock to answer all your legal questions, from urgent matters to everyday legal concerns.',
    familyLaw: 'Family Law: We provide support in matters of divorce, child custody, alimony, and other family issues.',
    criminalLaw: 'Criminal Law: Specialized defense and consulting in criminal cases, ensuring your rights are protected.',
    laborLaw: 'Labor Law: We help with issues of labor disputes, dismissals, wages, and other labor rights.',
    civilLaw: 'Civil Law: Covering a wide range of civil cases, from minor to medium and graduated difficulty.',
    personalDataLaw: 'Personal Data Law',
    personalDataLawText: 'Violation of individual privacy is the scourge of our time. Protection exists if you know where to find it.',
    personalRehabilitationLaw: 'Law on Rehabilitation of Individuals and Businesses:',
    personalRehabilitationLawText: 'Over-indebtedness is the number one issue and causes uncertainty. However, with the right plan and direction, there is always another option.',
    taxLaw: 'Tax Law:',
    taxLawText: 'The relationship between the state and citizens in Greece is hostile, as everyone admits. Only with expertise and proper guidance can you avoid unbearable fines and surcharges from tax authorities.',
    realEstateLaw: 'Real Estate Law:',
    realEstateLawText: 'Leases, transfers, urban planning issues, distribution of property among heirs are some of the daily issues that concern property owners in Greece. Methods and plans for proper and prudent property management are the only option.',
    enforcementLaw: 'Law on Compulsory Execution and Prevention of Auctions.',
    enforcementLawText: 'Timely and within strict time limits, contesting auction procedures is the best negotiation weapon for your future discussions with any creditor.',

    expropriationLaw: 'Law on Compulsory Expropriations.',
    expropriationLawText: 'Have you lost your property to the state and not been compensated? We undertake the entire process of your payment or even the release of your property so you can use it as you wish.',
    trafficAccidentsLaw: 'Traffic Accidents:',
    trafficAccidentsLawText: 'Dealing with insurance companies is a difficult case. Get the compensation you truly deserve, without cuts or the fine print of insurance policies.',
    medicalMalpractice: 'Medical Malpractice',
    medicalMalpracticeText: 'and omissions of the healthcare system.',
    subsidyReturns: 'Returns of Subsidies from Investment Law and ESPA:',
    subsidyReturnsText: 'The state often demands the return of the entire investment for minor irregularities. We handle the situation both administratively and by appealing to the competent courts to prevent this injustice against you.',
    chatbotServices: 'Services via Chatbot:',
    chatbotServicesText: 'Our smart chatbot is available to answer all your legal questions in any language. Just type your message in the language you prefer. This service is paid for a symbolic fee to cover the cost of maintaining a huge legal database.',
    onlineLawyerAppointment: 'Online Lawyer Appointment:',
    onlineLawyerAppointmentText: 'Book your appointment with a specialized lawyer within the next 24 hours and receive the support you need. Our services are paid.',

    howWeCanHelp: 'How We Can Help:',
    easyAccess: 'Easy Access:',
    easyAccessText: 'With our online platform, you can easily and quickly book appointments, receive advice, and chat with a lawyer.',
    immediateResponse: 'Immediate Response:',
    immediateResponseText: 'We are here to provide you with immediate assistance whenever you need it, without delays.',
    expertiseExperience: 'Expertise and Experience:',
    expertiseExperienceText: 'Our team consists of experienced lawyers specializing in various areas of law, ensuring the best possible support.',
    affordableServices: 'Affordable Services:',
    affordableServicesText: 'We offer affordable solutions to ensure that legal support is accessible to all citizens 24/7.',
    contactUs: 'Contact Us:',
    contactUsText: 'Don’t let legal problems trouble you. Contact us today and let us help you find the solution you need. We are here for you, any time of the day, every day of the week.',
    dikigorosOnline: 'DIKIGOROS ONLINE - Legal Services Everywhere and Always',

    instructionsTitle: 'Instructions',
    instructionsText1: 'On our website, you can use the online consultation with a virtual assistant. To do this, on the main page, click the "Online lawyer chat" button. You will need to pay a subscription, which will be valid for 24 hours.',
    instructionsText2: 'If you have a complex issue, you can use the "Booking 24" button. Our lawyers will review it and get back to you within 24 hours.',

    onlineChatText1: 'Online ',
    onlineChatText1_1: 'ΔικηγOρος Chat',
    onlineChatText2: 'Στην ιστοσελίδα μας μπορείτε να χρησιμοποιήσετε την online συμβουλή με τον εικονικό βοηθό. Για αυτό κλικ στο κουμπί "Online Δικηγόρος Chat". Θα χρειαστεί να πληρώσετε μια συνδρομή, η οποία θα ισχύει για 24 ώρες.'
  }
};
