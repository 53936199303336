import React, { useEffect, useState } from 'react';
import './services.css';

import service_img1 from '../../static/img/services_page/services_page_img1.png';
import service_img2 from '../../static/img/services_page/services_page_img2.png';
import service_img3 from '../../static/img/services_page/services_page_img3.png';
import service_img4 from '../../static/img/services_page/services_page_img4.png';
import service_img5 from '../../static/img/services_page/services_page_img5.png';
import service_img6 from '../../static/img/services_page/services_page_img6.png';
import service_img7 from '../../static/img/services_page/services_page_img7.png';
import service_img8 from '../../static/img/services_page/services_page_img8.png';
import service_img9 from '../../static/img/services_page/services_page_img9.png';
import service_img10 from '../../static/img/services_page/services_page_img10.png';
import service_img11 from '../../static/img/services_page/services_page_img11.png';
import service_img12 from '../../static/img/services_page/services_page_img12.png';
import service_img13 from '../../static/img/services_page/services_page_img13.png';
import service_img14 from '../../static/img/services_page/services_page_img14.png';

import { useSelector } from 'react-redux';
import { selectLanguage } from '../home/homeSlice';

const testimonials = [
    {
        text: 'This is an amazing product!',
        name: 'John Doe',
        avatar: 'https://via.placeholder.com/50'
    },
    {
        text: 'I had a great experience with this service.',
        name: 'Jane Smith',
        avatar: 'https://via.placeholder.com/50'
    },
    {
        text: 'Highly recommend to everyone!',
        name: 'Alice Johnson',
        avatar: 'https://via.placeholder.com/50'
    }
];

const Services = ({ setActiveMenu }) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [direction, setDirection] = useState('');
    const [email, setEmailInput] = useState('');
    const [password, setPassword] = useState('');

    const language = useSelector(selectLanguage)

    const prevSlide = () => {
        setDirection('left');
        const index = currentIndex === 0 ? testimonials.length - 1 : currentIndex - 1;
        setCurrentIndex(index);
    };

    const nextSlide = () => {
        setDirection('right');
        const index = currentIndex === testimonials.length - 1 ? 0 : currentIndex + 1;
        setCurrentIndex(index);
    };

    const handleLogin = () => {
        console.log('handleLogin')
    }

    useEffect(() => {
        setEmailInput('')
    }, []);

    return (
        <div>
            <div className="services_title_cntr">
                {/* <label className='title1_wellcome'>ΥΠΗΡΕΣΙΕΣ</label> */}
                <div className='title1_line' style={{ marginTop: '140px' }}></div>
                <label className='title1_wellcome2'>
                    ΥπηρεσΙες
                </label>
            </div>

            <div className='services_row' style={{ marginTop: '60px' }}>
                <div style={{ width: '630px', position: 'relative' }}>
                    <img style={{ width: '630px' }} src={service_img1} alt='' />
                    <label className='services_row_item_text1'>ΝομικΕς ΣυμβουλΕς 24/7</label>
                    <label className='services_row_item_text2'>Οι δικηγόροι μας είναι διαθέσιμοι όλο το 24ωρο για να απαντήσουν σε όλα σας τα νομικά ερωτήματα, από επείγοντα ζητήματα μέχρι καθημερινές νομικές ανησυχίες.</label>
                </div>
                <div style={{ width: '630px', position: 'relative' }}>
                    <img style={{ width: '630px' }} src={service_img2} alt='' />
                    <label className='services_row_item_text1'>ΟικογενειακΟ ΔΙκαιο</label>
                    <label className='services_row_item_text2'>Σας παρέχουμε υποστήριξη σε θέματα διαζυγίων, επιμέλειας παιδιών, διατροφής και άλλων οικογενειακών υποθέσεων.</label>
                </div>
            </div>

            <div className='services_row' style={{ marginTop: '40px' }}>
                <div style={{ width: '630px', position: 'relative' }}>
                    <img style={{ width: '630px' }} src={service_img3} alt='' />
                    <label className='services_row_item_text1'>ΠοινικΟ ΔΙκαιο</label>
                    <label className='services_row_item_text2'>Εξειδικευμένη υπεράσπιση και συμβουλευτική σε ποινικές υποθέσεις, διασφαλίζοντας τα δικαιώματά σας.</label>
                </div>
                <div style={{ width: '630px', position: 'relative' }}>
                    <img style={{ width: '630px' }} src={service_img4} alt='' />
                    <label className='services_row_item_text1'>ΕργατικΟ ΔΙκαιο</label>
                    <label className='services_row_item_text2'>Βοηθάμε σε ζητήματα εργασιακών διαφορών, απολύσεων, αμοιβών και άλλων εργασιακών δικαιωμάτων.</label>
                </div>
            </div>

            <div className='services_row' style={{ marginTop: '40px' }}>
                <div style={{ width: '630px', position: 'relative' }}>
                    <img style={{ width: '630px' }} src={service_img5} alt='' />
                    <label className='services_row_item_text1'>ΑστικΟ ΔΙκαιο</label>
                    <label className='services_row_item_text2'>Καλύπτουμε ένα ευρύ φάσμα αστικών υποθέσεων, μικρής , μέσης και διαβαθμισμένης δυσκολίας.</label>
                </div>
                <div style={{ width: '630px', position: 'relative' }}>
                    <img style={{ width: '630px' }} src={service_img6} alt='' />
                    <label className='services_row_item_text1'>ΔΙΚΑΙΟ ΠΡΟΣΩΠΙΚΩΝ ΔΕΔΟΜΕΝΩΝ</label>
                    <label className='services_row_item_text2'>Η προσβολή της ιδιωτικότητας του ατόμου στις μέρες μας είναι η μάστιγα της εποχής. Υπάρχει προστασία αρκεί να ξέρεις να την βρεις.</label>
                </div>
            </div>

            <div className='services_row' style={{ marginTop: '40px' }}>
                <div style={{ width: '630px', position: 'relative' }}>
                    <img style={{ width: '630px' }} src={service_img7} alt='' />
                    <label className='services_row_item_text1'>ΔΙΚΑΙΟ ΕΞΥΓΙΑΝΣΗΣ ΦΥΣΙΚΩΝ ΠΡΟΣΩΠΩΝ ΚΑΙ ΕΠΙΧΕΙΡΗΣΕΩΝ</label>
                    <label className='services_row_item_text2'>Η υπερχρέωση αποτελεί το νούμερο ένα ζήτημα και προκαλεί αβεβαιότητα. Με σωστό πλάνο και κατεύθυνση όμως πάντα υπάρχει και η άλλη επιλογή.</label>
                </div>
                <div style={{ width: '630px', position: 'relative' }}>
                    <img style={{ width: '630px' }} src={service_img8} alt='' />
                    <label className='services_row_item_text1'>ΦΟΡΟΛΟΓΙΚΟ ΔΙΚΑΙΟ</label>
                    <label className='services_row_item_text2'>Η σχέση κράτους πολίτη στην Ελλάδα είναι όπως όλοι ομολογούν εχθρική. Μόνο με εξειδίκευση και ορθή κατεύθυνση αποφεύγεις δυσβάσταχτα πρόστιμα και προσαυξήσεις από τις φορολογικές αρχές.</label>
                </div>
            </div>

            <div className='services_row' style={{ marginTop: '40px' }}>
                <div style={{ width: '630px', position: 'relative' }}>
                    <img style={{ width: '630px' }} src={service_img9} alt='' />
                    <label className='services_row_item_text1'>ΔΙΚΑΙΟ ΑΚΙΝΗΤΩΝ</label>
                    <label className='services_row_item_text2'>Μισθώσεις, μεταβιβάσεις, πολεοδομικά θέματα, διανομή περιουσίας μεταξύ κληρονόμων μερικά από τα καθημερινά ζητήματα που απασχολούν τον ιδιοκτήτη ακινήτου στην Ελλάδα.</label>
                </div>
                <div style={{ width: '630px', position: 'relative' }}>
                    <img style={{ width: '630px' }} src={service_img10} alt='' />
                    <label className='services_row_item_text1'>ΔΙΚΑΙΟ ΑΝΑΓΚΑΣΤΙΚΗΣ ΕΚΤΕΛΕΣΗΣ ΚΑΙ ΑΠΟΤΡΟΠΗΣ ΠΛΕΙΣΤΗΡΙΑΣΜΩΝ</label>
                    <label className='services_row_item_text2'>Η έγκαιρη και εντός των αυστηρών χρονικών ορίων ανακοπή των διαδικασιών πλειστηριασμού αποτελεί το καλύτερο διαπραγματευτικό όπλο για μελλοντικές συζητήσεις σας με κάθε πιστωτή σας.</label>
                </div>
            </div>

            <div className='services_row' style={{ marginTop: '40px' }}>
                <div style={{ width: '630px', position: 'relative' }}>
                    <img style={{ width: '630px' }} src={service_img11} alt='' />
                    <label className='services_row_item_text1'>ΔΙΚΑΙΟ ΑΝΑΓΚΑΣΤΙΚΩΝ ΑΠΑΛΛΟΤΡΙΩΣΕΩΝ</label>
                    <label className='services_row_item_text2'>Έχετε χάσει την περιουσία σας από το κράτος και δεν έχετε αποζημιωθεί; Αναλαμβάνουμε όλη τη διαδικασία πληρωμής σας ή ακόμη και της αποδέσμευσης της περιουσίας σας για να την αξιοποιήσετε όπως εσείς επιθυμείτε.</label>
                </div>
                <div style={{ width: '630px', position: 'relative' }}>
                    <img style={{ width: '630px' }} src={service_img12} alt='' />
                    <label className='services_row_item_text1'>ΤΡΟΧΑΙΑ ΑΤΥΧΗΜΑΤΑ</label>
                    <label className='services_row_item_text2'>Μια δύσκολη περίπτωση η έμπλοκη σας με τις ασφαλιστικές εταιρείες. Εισπράξτε την αποζημίωση που πραγματικά δικαιούστε, χωρίς περικοπές ή τα ψιλά γράμματα των ασφαλιστηρίων συμβολαίων.</label>
                </div>
            </div>

            <div className='services_row' style={{ marginTop: '40px' }}>
                <div style={{ width: '630px', position: 'relative' }}>
                    <img style={{ width: '630px' }} src={service_img13} alt='' />
                    <label className='services_row_item_text1' style={{top: '142px'}}>ΙΑΤΡΙΚΑ <br/>ΛΑΘΗ</label>
                    <label className='services_row_item_text2' style={{top: '224px'}}>Η έγκαιρη και εντός των αυστηρών χρονικών ορίων ανακοπή των διαδικασιών πλειστηριασμού αποτελεί το καλύτερο διαπραγματευτικό όπλο για μελλοντικές συζητήσεις σας με κάθε πιστωτή σας.</label>
                </div>
                <div style={{ width: '630px', position: 'relative' }}>
                    <img style={{ width: '630px' }} src={service_img14} alt='' />
                    <label className='services_row_item_text1' style={{top: '142px'}}>ΕΠΙΣΤΡΟΦΕΣ ΕΠΙΔΟΤΗΣΕΩΝ ΑΠΟ ΕΠΕΝΔΥΤΙΚΟ ΝΟΜΟ ΚΑΙ ΕΣΠΑ</label>
                    <label className='services_row_item_text2' style={{top: '224px'}}>Συχνά το κράτος για μικρές παρατυπίες ζητά επιστροφή του συνόλου της επένδυσης. Αντιμετωπίζουμε την όλη κατάσταση τόσο ενδοδιοικητικά όσο και με προσφυγή στα αρμόδια δικαστήρια προς αποτροπή αυτής της αδικίας εις βάρος σας.</label>
                </div>
            </div>

            <div className='services_row' style={{ marginTop: '40px' }}>
                <div style={{ width: '630px', position: 'relative', height: '350px', background: '#333B5F' }}>
                    <label className='services_row_item_text1' style={{top: '100px'}}>Online ΔικηγΟρος Chat</label>
                    <label className='services_row_item_text2' style={{top: '151px'}}>Στην ιστοσελίδα μας μπορείτε να χρησιμοποιήσετε την online συμβουλή με τον εικονικό βοηθό. Για αυτό κλικ στο κουμπί "Online Δικηγόρος Chat". Θα χρειαστεί να πληρώσετε μια συνδρομή, η οποία θα ισχύει για 24 ώρες.</label>
                    <div className='login_widget_button instruction_btn_txt' style={{ width: '220px', marginTop: '263px', marginLeft: '30px' }}>
                        Online Δικηγόρος Chat
                    </div>
                </div>
                <div style={{ width: '630px', position: 'relative', background: '#333B5F' }}>
                    <label className='services_row_item_text1' style={{top: '100px'}}>ΚρΑτηση 24</label>
                    <label className='services_row_item_text2' style={{top: '151px'}}>Αν έχετε ένα δύσκολο ζήτημα, μπορείτε να χρησιμοποιήσετε το κουμπί "Κράτηση 24". Οι δικηγόροι μας θα το εξετάσουν και θα επικοινωνήσουν<br/> μαζί σας εντός 24 ωρών.</label>
                    <div className='login_widget_button instruction_btn_txt' style={{ width: '220px', marginTop: '263px', marginLeft: '30px' }}>
                        Κράτηση 24/7
                    </div>
                </div>
            </div>


            {/* <div className='services_row' style={{ marginTop: '20px'}}>
                <div style={{ width: '420px' }}>
                    <img style={{ width: '420px' }} src={service_img4} alt='' />
                </div>
                <div style={{ width: '420px' }}>
                    <img style={{ width: '420px' }} src={service_img5} alt='' />
                </div>
                <div style={{ width: '420px', height: '520px' }}>
                    <div className='cntr_login_widget'>
                        <label className='login_widget_title'>Online ΔικηγΟρος Cha</label>
                        <input
                            style={{
                                WebkitBoxShadow: '0 0 0 1000px transparent inset',
                                boxShadow: '0 0 0 1000px transparent inset',
                                WebkitTextFillColor: 'inherit',
                                backgroundColor: 'transparent',
                                marginTop: '20px',
                                color: 'white'
                              }}
                            className='login_widget_input'
                            type="email"
                            placeholder="Email"
                            value={email}
                            onChange={(e) => setEmailInput(e.target.value)}
                        />
                        <input
                            className='login_widget_input'
                            type="password"
                            placeholder={translations[language].password}
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                        <button className='login_widget_button' onClick={handleLogin}>{translations[language].login}</button>
                        <p style={{textDecoration: 'underline'}} onClick={() => setActiveMenu('reg')}>{translations[language].registration}</p>
                        <label className='login_widget_footer_text'>Δίνω τη συγκατάθεσή μου για την επεξεργασία προσωπικών δεδομένων</label>
                    </div>
                </div>
            </div> */}
            {/* <button className='services_button' onClick={handleLogin}>{translations[language].login}</button> */}
        </div>

    );
};

export default Services;
