
import React, { useState, useRef, useEffect } from 'react';
import './consultant.css';
import { sendQuestion } from './apiConsultant';
import NavMenu from '../navMenu/navMenu';
import logo_menu from '../../static/img/logo_menu.png';

const OnlineConsultant = ({ email }) => {
    const [text, setText] = useState('');
    const [loading, setLoading] = useState(false);
    const [chat, setChat] = useState([{ type: '', text: 'hello' }, { type: 'question', text: 'hello' }]);
    const textAreaRef = useRef(null);
    const chatRef = useRef(null);

    const handleChange = (event) => {
        setText(event.target.value);
    };

    useEffect(() => {
        if (textAreaRef.current) {
            textAreaRef.current.style.height = 'auto';
            textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px`;
        }
    }, [text]);

    useEffect(() => {
        if (chatRef.current) {
            chatRef.current.scrollTop = chatRef.current.scrollHeight;
        }
    }, [chat]);

    const handleSubmit = async () => {
        if (text.trim() === '') return;
        setLoading(true);
        const userMessage = { type: 'question', text };
        setChat((prevChat) => [...prevChat, userMessage]);
        setText('');
        const result = await sendQuestion(email, text);
        if (result) {
            const answerMessage = { type: 'answer', text: result.answer };
            setChat((prevChat) => [...prevChat, answerMessage]);
            setLoading(false);
        }
    };

    return (
        <div className="consultant-container">
            <div className='bg_menu  consultant_cntr_menu' style={{ width: '100vw', position: 'relative', height: '100px' }}>

                <NavMenu />
            </div>
            <div className="chat-container" ref={chatRef}>
                {chat.map((msg, index) => (
                    <>

                        {msg.type === 'question' ?
                            <div style={{ width: '100%', display: 'flex', justifyContent: 'left' }}>
                                <div className=' answer'>
                                    <div className='cntr_logo_msg'>
                                        <img src={logo_menu} alt="Logo" className="logo_msg" />
                                    </div>
                                    <div style={{ textAlign: 'left', marginLeft: '20px' }}>
                                        <div className='chat-message'>
                                            {msg.text}
                                        </div>
                                        <span className='time_text' style={{ marginTop: '5px' }}>12:09</span>
                                    </div>

                                </div>
                            </div> :
                            <div style={{ width: '100%', display: 'flex', justifyContent: 'right' }}>
                                <div style={{ textAlign: 'right'}}>
                                    <div className='chat_message_user'>
                                        {msg.text}
                                    </div>
                                    <span className='time_text' style={{ marginTop: '5px' }}>12:09</span>
                                </div>
                            </div>


                        }
                    </>
                ))}
            </div>
            {/* <textarea
                ref={textAreaRef}
                className="text-area"
                value={text}
                onChange={handleChange}
                placeholder="Παρακαλώ εισάγετε την ερώτησή σας εδώ..."
            />
            {loading ? (
                <p>Αναμονή για απάντηση...</p>
            ) : (
                <button className="send-button" onClick={handleSubmit}>Αποστολή</button>
            )} */}
            <div className="input-container">
                <textarea
                    ref={textAreaRef}
                    className="text-area"
                    value={text}
                    onChange={handleChange}
                    placeholder="Παρακαλώ εισάγετε την ερώτησή σας εδώ..."
                />
                {loading ? (
                    <p>Αναμονή για απάντηση...</p>
                ) : (
                    <button className="send-button" onClick={handleSubmit}>Αποστολή</button>
                )}
            </div>
        </div>
    );
};

export default OnlineConsultant;

