import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { selectLanguage } from '../home/homeSlice';
import { translations } from '../home/translation';

const BtnChat = ({start}) => {
    const navigate = useNavigate();
    const language = useSelector(selectLanguage)

    const handleOnlineClick = () => {
        if (start) navigate('/chat/start');
        else navigate('/chat');
    };

    return (
        <div className="btn_online_txt btn_online" onClick={handleOnlineClick}>
            {translations[language].onlineLawyerChat}
        </div>
    );
};

export default BtnChat;
