import React, { useState } from 'react';
import './footer.css';
import logo_menu from '../../static/img/logo_menu.png';
import { translations } from './translation';
import { useSelector } from 'react-redux';
import { selectLanguage } from './homeSlice';



const Footer = ({ handleMenuClick }) => {

    const language = useSelector(selectLanguage)
    return (
        <div className='cntr_footer' style={{ marginTop: '140px' }}>
            <div className='cntr_footer_row'>
                <div style={{ width: '64px' }}>
                    <img src={logo_menu} alt="Logo" className="footer_logo" />
                </div>
                <div className='footer_cntr_menu'>

                    <span className="nav-item" onClick={() => handleMenuClick('main')}>{translations[language].home}</span>
                    <span className="nav-item" onClick={() => handleMenuClick('we')}>{translations[language].aboutUs}</span>
                    <span className="nav-item" onClick={() => handleMenuClick('we')}>ΥΠΗΡΕΣΙΕΣ</span>

                    <span className="nav-item" onClick={() => handleMenuClick('instructions')}>{translations[language].instructions}</span>
                    <span className="nav-item" onClick={() => { }}>{translations[language].forLawyers}</span>

                </div>
                <div className='nav-item txt_cap_under' style={{marginTop: '31px'}}>
                    Κράτηση 24/7
                </div>
            </div>
            <div className='cntr_footer_line' style={{marginTop: '40px'}}>
                <svg className='svg_line' width="1300" height="1" viewBox="0 0 1300 1" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <line y1="0.5" x2="1300" y2="0.5" stroke="#A0A0A0" />
                </svg>
            </div>

            <div className='footer_cntr_links' style={{ marginTop: '40px', width: '1300px'}}>
                <div className='footer_link_online'>
                    DIKIGOROS ONLINE
                </div>
                <div className='footer_link_online'>
                    DIKIGOROS ONLINE
                </div>
            </div>
        </div>
    );
};

export default Footer;
