import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  status: '',
  email: '',
  datetime: '',
  language: 'Greek'
};

export const homeSlice = createSlice({
  name: 'home',
  initialState,
  reducers: {
    setStatus: (state, action) => {
      state.status = action.payload;
    },
    
    setEmail: (state, action) => {
      state.email = action.payload;
    },

    setDatetime: (state, action) => {
      state.datetime = action.payload;
    },

    setLanguage: (state, action) => {
      state.language = action.payload;
    }

    
  },
});

export const { setLanguage, setEmail, setStatus, setDatetime } = homeSlice.actions;

export const selectStatus = (state) => state.home.status;
export const selectEmail = (state) => state.home.email;
export const selectDatetime = (state) => state.home.datetime;
export const selectLanguage = (state) => state.home.language;

export default homeSlice.reducer;
