import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import logo_menu from '../../static/img/logo_menu.png'; 

import { svg_out, svg_burger } from '../../static/svg';



import { useNavigate } from 'react-router-dom';
import LanguageSelector from '../home/languageSelector';
import { selectEmail, selectLanguage, setEmail } from '../home/homeSlice';
import { translations } from '../home/translation';

import '../home/home.css'

const NavMenu = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const language = useSelector(selectLanguage)
    const email = useSelector(selectEmail)
    
    const dispatch = useDispatch();
    const navigate = useNavigate()

    const goToServicesPage = () => {
        navigate('/services'); // Программный переход на страницу /about
    };

    const goToInstructionsPage = () => {
        navigate('/instructions'); // Программный переход на страницу /about
    };

    const handleMenuClick = () => {}

    return (
        <>
            {/* Десктопная навигация */}
            <nav className="nav-menu" style={{ position: 'absolute', top: "0px", zIndex: 1 }}>
                <div style={{ width: '64px', marginTop: '21px' }}>
                    <img src={logo_menu} alt="Logo" className="logo_menu nav-item" />
                </div>
                <div style={{ marginTop: '41px' }} className='nav-item'>
                    <LanguageSelector />
                </div>

                <div className='cntr_menu'>
                    <span className="nav-item" onClick={() => handleMenuClick('main')}>
                        {translations[language].home}
                    </span>
                    <span className="nav-item" onClick={() => handleMenuClick('we')}>
                        {translations[language].aboutUs}
                    </span>
                    <span className="nav-item" onClick={goToServicesPage}>
                        ΥΠΗΡΕΣΙΕΣ
                    </span>
                    <span className="nav-item" onClick={goToInstructionsPage}>
                        {translations[language].instructions}
                    </span>
                    <span className="nav-item" onClick={() => { }}>
                        {translations[language].forLawyers}
                    </span>
                </div>

                <div className='nav-item txt_cap_under' style={{ marginTop: '31px', marginLeft: '270px' }}>
                    Κράτηση 24/7
                </div>

                {email !== '' && (
                    <div onClick={() => dispatch(setEmail(''))} className='svg_out'>
                        {svg_out}
                    </div>
                )}
            </nav>

            {/* Мобильная навигация */}
            <nav className="nav-menu-mobile">
                <div style={{ width: '150px' }}>
                    <img src={logo_menu} alt="Logo" className="logo nav-item" />
                </div>

                <span className="burger-menu" onClick={() => setIsMenuOpen(!isMenuOpen)}>
                    {svg_burger}
                </span>

                <div className='svg_globus'>
                    <LanguageSelector />
                </div>

                {isMenuOpen && (
                    <div className="mobile-menu">
                        <span className="nav-item" onClick={() => handleMenuClick('main')}>
                            {translations[language].home}
                        </span>
                        <span className="nav-item" onClick={() => handleMenuClick('we')}>
                            {translations[language].aboutUs}
                        </span>
                        <span className="nav-item" onClick={() => handleMenuClick('instructions')}>
                            {translations[language].instructions}
                        </span>
                        <span className="nav-item" onClick={() => { }}>
                            {translations[language].forLawyers}
                        </span>
                    </div>
                )}
            </nav>
        </>
    );
};

export default NavMenu;
