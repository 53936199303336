import React, { useState } from 'react';
import './services.css';
import './instruction.css';
import { svg_advantage } from '../../static/svg'
import { useSelector } from 'react-redux';
import { selectLanguage } from './homeSlice';
import { translations } from './translation';



const Instruction = ({ setActiveMenu }) => {


    return (
        <div style={{marginTop: '140px'}} className='cntr_instruction'>
            <div className="services_title_cntr">
                <label className='title1_wellcome'>ΥΠΗΡΕΣΙΕΣ</label>
                <div className='title1_line'></div>
                <label style={{marginTop: '20px'}} className='title1_wellcome2'>
                    ΟΙ ΥΠΗΡΕΣΙΕΣ ΜΑΣ
                </label>
            </div>

            <div style={{marginTop: '60px'}} className='row_instruction'>
                <div className='cntr_instruction_item'>
                    <div className='title_instruction_item'>Online ΔικηγΟρος Chat</div>
                    <div className='text_intruction_item'>
                        Στην ιστοσελίδα μας μπορείτε να χρησιμοποιήσετε την online συμβουλή με τον εικονικό βοηθό. Για αυτό κλικ στο κουμπί "Online Δικηγόρος Chat". Θα χρειαστεί να πληρώσετε μια συνδρομή, η οποία θα ισχύει για 24 ώρες.
                    </div>
                    <div className='login_widget_button instruction_btn_txt' style={{ width: '220px', marginTop: '20px' }}>
                        Online Δικηγόρος Chat
                    </div>
                </div>
                <div className='cntr_instruction_item'>
                    <div className='title_instruction_item'>Online ΔικηγΟρος Chat</div>
                    <div className='text_intruction_item'>
                        Στην ιστοσελίδα μας μπορείτε να χρησιμοποιήσετε την online συμβουλή με τον εικονικό βοηθό. Για αυτό κλικ στο κουμπί "Online Δικηγόρος Chat". Θα χρειαστεί να πληρώσετε μια συνδρομή, η οποία θα ισχύει για 24 ώρες.
                    </div>
                    <div className='login_widget_button instruction_btn_txt' style={{ width: '220px', marginTop: '20px' }}>
                        Κράτηση 24/7
                    </div>
                </div>
            </div>
            <div className='services_title_cntr'>
                <div className='login_widget_button' style={{ width: '1300px', marginTop: '40px' }}>
                    Μάθετε περισσότερα
                </div>

            </div>
        </div>
    );
};

export default Instruction;
