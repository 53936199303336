import React, { useState } from 'react';
import './services.css';
import './advantage.css';
import { svg_advantage } from '../../static/svg'
import { useSelector } from 'react-redux';
import { selectLanguage } from './homeSlice';

const testimonials = [
    {
        text: 'This is an amazing product!',
        name: 'John Doe',
        avatar: 'https://via.placeholder.com/50'
    },
    {
        text: 'I had a great experience with this service.',
        name: 'Jane Smith',
        avatar: 'https://via.placeholder.com/50'
    },
    {
        text: 'Highly recommend to everyone!',
        name: 'Alice Johnson',
        avatar: 'https://via.placeholder.com/50'
    }
];

const Advantage = ({ setActiveMenu }) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [direction, setDirection] = useState('');
    const [email, setEmailInput] = useState('');
    const [password, setPassword] = useState('');

    const language = useSelector(selectLanguage)

    const prevSlide = () => {
        setDirection('left');
        const index = currentIndex === 0 ? testimonials.length - 1 : currentIndex - 1;
        setCurrentIndex(index);
    };

    const nextSlide = () => {
        setDirection('right');
        const index = currentIndex === testimonials.length - 1 ? 0 : currentIndex + 1;
        setCurrentIndex(index);
    };

    const handleLogin = () => {
        console.log('handleLogin')
    }

    return (
        <div style={{ width: '1300px', marginTop: '160px' }}>
            <div className="services_title_cntr">
                <label className='title1_wellcome'>ΠΛΕΟΝΕΚΤΗΜΑ</label>
                <div className='title1_line'></div>
                <label className='title1_wellcome2' style={{ marginTop: '20px'}}>
                    ΠΩΣ ΜΠΟΡΟΥΜΕ ΝΑ<br></br> ΣΑΣ ΒΟΗΘΗΣΟΥΜΕ
                </label>
            </div>

            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '60px' }}>
                <div className='advantage_item_cntr'>
                    <div style={{ position: 'relative' }}>
                        {svg_advantage}
                        <label className='advantage_text'>ΕΥκολη ΠρΟσβαση</label>
                    </div>
                    <label className='advantage_item_text'>Με την διαδικτυακή μας πλατφόρμα, μπορείτε να κλείσετε ραντεβού, να λάβετε συμβουλές και να συνομιλήσετε με δικηγόρο εύκολα και γρήγορα.</label>
                </div>
                <div className='advantage_item_cntr'>
                    <div style={{ position: 'relative' }}>
                        {svg_advantage}
                        <label className='advantage_text'>ΕΥκολη ΠρΟσβαση</label>
                    </div>
                    <label className='advantage_item_text'>Με την διαδικτυακή μας πλατφόρμα, μπορείτε να κλείσετε ραντεβού, να λάβετε συμβουλές και να συνομιλήσετε με δικηγόρο εύκολα και γρήγορα.</label>
                </div>
                <div className='advantage_item_cntr'>
                    <div style={{ position: 'relative' }}>
                        {svg_advantage}
                        <label className='advantage_text'>ΕΥκολη ΠρΟσβαση</label>
                    </div>
                    <label className='advantage_item_text'>Με την διαδικτυακή μας πλατφόρμα, μπορείτε να κλείσετε ραντεβού, να λάβετε συμβουλές και να συνομιλήσετε με δικηγόρο εύκολα και γρήγορα.</label>
                </div>
                <div className='advantage_item_cntr'>
                    <div style={{ position: 'relative' }}>
                        {svg_advantage}
                        <label className='advantage_text'>ΕΥκολη ΠρΟσβαση</label>
                    </div>
                    <label className='advantage_item_text'>Με την διαδικτυακή μας πλατφόρμα, μπορείτε να κλείσετε ραντεβού, να λάβετε συμβουλές και να συνομιλήσετε με δικηγόρο εύκολα και γρήγορα.</label>
                </div>
            </div>
        </div>

    );
};

export default Advantage;
