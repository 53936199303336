import React, { useEffect, useState } from 'react';
import './services.css';
import wellcome_img from '../../static/img/wellcome.png';

import service_img1 from '../../static/img/service_img1.png'
import service_img2 from '../../static/img/service_img2.png'
import service_img3 from '../../static/img/service_img3.png'

import service_img4 from '../../static/img/service_img4.png'
import service_img5 from '../../static/img/service_img5.png'
import { translations } from './translation';
import { useSelector } from 'react-redux';
import { selectLanguage } from './homeSlice';

const testimonials = [
    {
        text: 'This is an amazing product!',
        name: 'John Doe',
        avatar: 'https://via.placeholder.com/50'
    },
    {
        text: 'I had a great experience with this service.',
        name: 'Jane Smith',
        avatar: 'https://via.placeholder.com/50'
    },
    {
        text: 'Highly recommend to everyone!',
        name: 'Alice Johnson',
        avatar: 'https://via.placeholder.com/50'
    }
];

const Services = ({ setActiveMenu }) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [direction, setDirection] = useState('');
    const [email, setEmailInput] = useState('');
    const [password, setPassword] = useState('');

    const language = useSelector(selectLanguage)

    const prevSlide = () => {
        setDirection('left');
        const index = currentIndex === 0 ? testimonials.length - 1 : currentIndex - 1;
        setCurrentIndex(index);
    };

    const nextSlide = () => {
        setDirection('right');
        const index = currentIndex === testimonials.length - 1 ? 0 : currentIndex + 1;
        setCurrentIndex(index);
    };

    const handleLogin = () => {
        console.log('handleLogin')
    }

    useEffect(() => {
        setEmailInput('')
    }, []);

    return (
        <div>
            <div className="services_title_cntr">
                <label className='title1_wellcome'>ΥΠΗΡΕΣΙΕΣ</label>
                <div className='title1_line'></div>
                <label className='title1_wellcome2'>
                    ΟΙ ΥΠΗΡΕΣΙΕΣ ΜΑΣ
                </label>
            </div>

            <div className='services_row' style={{ marginTop: '60px'}}>
                <div style={{ width: '420px' }}>
                    <img style={{ width: '420px' }} src={service_img1} alt='' />
                </div>
                <div style={{ width: '420px' }}>
                    <img style={{ width: '420px' }} src={service_img2} alt='' />
                </div>
                <div style={{ width: '420px' }}>
                    <img style={{ width: '420px' }} src={service_img3} alt='' />
                </div>
            </div>
            <div className='services_row' style={{ marginTop: '20px'}}>
                <div style={{ width: '420px' }}>
                    <img style={{ width: '420px' }} src={service_img4} alt='' />
                </div>
                <div style={{ width: '420px' }}>
                    <img style={{ width: '420px' }} src={service_img5} alt='' />
                </div>
                <div style={{ width: '420px', height: '520px' }}>
                    <div className='cntr_login_widget'>
                        <label className='login_widget_title'>Online ΔικηγΟρος Cha</label>
                        <input
                            style={{
                                WebkitBoxShadow: '0 0 0 1000px transparent inset',
                                boxShadow: '0 0 0 1000px transparent inset',
                                WebkitTextFillColor: 'inherit',
                                backgroundColor: 'transparent',
                                marginTop: '20px',
                                color: 'white'
                              }}
                            className='login_widget_input'
                            type="email"
                            placeholder="Email"
                            value={email}
                            onChange={(e) => setEmailInput(e.target.value)}
                        />
                        <input
                            className='login_widget_input'
                            type="password"
                            placeholder={translations[language].password}
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                        <button className='login_widget_button' onClick={handleLogin}>{translations[language].login}</button>
                        <p style={{textDecoration: 'underline'}} onClick={() => setActiveMenu('reg')}>{translations[language].registration}</p>
                        <label className='login_widget_footer_text'>Δίνω τη συγκατάθεσή μου για την επεξεργασία προσωπικών δεδομένων</label>
                    </div>
                </div>
            </div>
            <button className='services_button' onClick={handleLogin}>{translations[language].login}</button>
        </div>

    );
};

export default Services;
