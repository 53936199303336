import React, { useState } from 'react';
import './services.css';
import './booking.css';
import { svg_advantage } from '../../static/svg'
import { useSelector } from 'react-redux';
import { selectLanguage } from './homeSlice';
import { translations } from './translation';



const Booking = ({ setActiveMenu }) => {
    const language = useSelector(selectLanguage)
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [question, setQuestion] = useState('');


    return (
        <div className='cntr_booking' style={{marginTop: '140px'}}>
            <div className='subcntr_booking'>
                <div style={{marginTop: '60px'}} className='title_booking'>ΚΛΕΙΣΤΕ ΡΑΝΤΕΒΟΥ</div>

                <div style={{marginTop: '40px'}} className='row_input_booking'>
                    <input
                        style={{
                            WebkitBoxShadow: '0 0 0 1000px transparent inset',
                            boxShadow: '0 0 0 1000px transparent inset',
                            WebkitTextFillColor: 'inherit',
                            backgroundColor: 'transparent',
                        }}
                        className='login_widget_input'
                        type="email"
                        placeholder="Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <input
                        className='login_widget_input'
                        type="password"
                        placeholder={translations[language].password}
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                    />
                </div>
                <div className='row_input_booking'>
                    <input
                        style={{
                            WebkitBoxShadow: '0 0 0 1000px transparent inset',
                            boxShadow: '0 0 0 1000px transparent inset',
                            WebkitTextFillColor: 'inherit',
                            backgroundColor: 'transparent',
                        }}
                        className='login_widget_input'
                        type="email"
                        placeholder="Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <input
                        className='login_widget_input'
                        type="password"
                        placeholder={translations[language].password}
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                    />
                </div>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <input
                        style={{ width: '740px', height: '70px' }}
                        className='login_widget_input'
                        type="password"
                        placeholder={translations[language].password}
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                    />
                </div>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <div className='btn_booking'>
                        Συνέχεια
                    </div>
                </div>

                <div className='subtitle_boking'>
                    Δίνω τη συγκατάθεσή μου για την επεξεργασία προσωπικών δεδομένων
                </div>

            </div>
        </div>
    );
};

export default Booking;
