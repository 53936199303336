import React, { useState } from 'react';
import './services.css';
import './instruction.css';
import './comments.css';
import { svg_advantage } from '../../static/svg'
import { useSelector } from 'react-redux';
import { selectLanguage } from './homeSlice';
import { translations } from './translation';



const Comments = ({ setActiveMenu }) => {


    return (
        <div className='cntr_instruction' style={{ marginTop: '140px'}}>
            <div className="services_title_cntr">
                <label className='title1_wellcome'>ΥΠΗΡΕΣΙΕΣ</label>
                <div className='title1_line' style={{ marginBottom: '20px'}}></div>
                <label className='title1_wellcome2' >
                    Τι εχουν πει<br/> για μας
                </label>
            </div>

            <div className='row_instruction' style={{ marginTop: '60px'}}>
                <div className='cntr_comments_item'>
                    <div className='title_comments_item'>Georgia Karamolegkou</div>
                    <div className='text_comments_item'>
                        Στην ιστοσελίδα μας μπορείτε να χρησιμοποιήσετε την online συμβουλή με τον εικονικό βοηθό. Για αυτό κλικ στο κουμπί "Online Δικηγόρος Chat". Θα χρειαστεί να πληρώσετε μια συνδρομή, η οποία θα ισχύει για 24 ώρες.
                    </div>
                </div>
                <div className='cntr_comments_item'>
                    <div className='title_comments_item'>ΝΙΚΟΛΑΟΣ ΜΕΞΑΣ</div>
                    <div className='text_comments_item'>
                        Στην ιστοσελίδα μας μπορείτε να χρησιμοποιήσετε την online συμβουλή με τον εικονικό βοηθό. Για αυτό κλικ στο κουμπί "Online Δικηγόρος Chat". Θα χρειαστεί να πληρώσετε μια συνδρομή, η οποία θα ισχύει για 24 ώρες.
                    </div>
                </div>
                <div className='cntr_comments_item'>
                    <div className='title_comments_item'>ΣΩΤΗΡΗΣ ΤΡΙΒΕΛΛΑΣ</div>
                    <div className='text_comments_item'>
                        Στην ιστοσελίδα μας μπορείτε να χρησιμοποιήσετε την online συμβουλή με τον εικονικό βοηθό. Για αυτό κλικ στο κουμπί "Online Δικηγόρος Chat". Θα χρειαστεί να πληρώσετε μια συνδρομή, η οποία θα ισχύει για 24 ώρες.
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Comments;
