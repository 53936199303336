import React, { useState } from 'react';
import './mainPage.css';
import { useDispatch, useSelector } from 'react-redux';
import { selectEmail, setEmail } from '../home/homeSlice';
import { sendLawyerQuestion } from '../lawyer/apiLawyer';
import Pdf from '../../static/privacypolicy.pdf';

const ModalData = ({ show, handleClose, handleSubmit }) => {
    const dispatch = useDispatch();
    const email = useSelector(selectEmail);
    const [phone, setPhone] = useState('');
    const [name, setName] = useState('');
    const [surname, setSurname] = useState('');
    const [question, setQuestion] = useState('');
    const [consent, setConsent] = useState(false);

    const handleClickPrivacyPolicy = () => {
        window.open(`../../../static/privacypolicy.pdf`, '_blank');
    }

    const onSubmit = () => {
        handleSubmit(email, phone, name, surname);
        sendLawyerQuestion(name, email, phone, question);
        handleClose();
    };

    if (!show) {
        return null;
    }

    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <h2>Κλείστε ραντεβού</h2>
                <label>Email:</label>
                <input type="email" value={email} onChange={(e) => dispatch(setEmail(e.target.value))} />
                <label>Phone:</label>
                <input type="tel" value={phone} onChange={(e) => setPhone(e.target.value)} />
                <label>Name:</label>
                <input type="text" value={name} onChange={(e) => setName(e.target.value)} />
                <label>Surname:</label>
                <input type="text" value={surname} onChange={(e) => setSurname(e.target.value)} />
                <label>Question:</label>
                <textarea
                    className='textarea_modal'
                    value={question}
                    onChange={(e) => setQuestion(e.target.value)}
                    placeholder="Παρακαλώ εισάγετε την ερώτησή σας εδώ..."
                />
                <div className="consent-container">
                    <input
                        style={{width: '30px'}}
                        type="checkbox"
                        checked={consent}
                        onChange={() => setConsent(!consent)}
                    />
                    <span style={{ color: 'black', fontSize: '12px'}}>Δίνω συγκατάθεση για την επεξεργασία των δεδομένων</span>
                </div>
                {/* <div onClick={handleClickPrivacyPolicy} style={{ color: 'black', fontSize: '12px', textAlign: 'left', cursor: 'pointer'}}>
                    Privacy Policy
                </div> */}
                <a style={{ color: 'black', fontSize: '12px', textAlign: 'left'}} rel="noreferrer" href = {Pdf} target = "_blank">Privacy Policy</a>
                <div className='modal_cntr_btns'>
                    <button onClick={onSubmit}>Συνέχεια</button>
                    <button onClick={handleClose}>Κλείσιμο</button>
                </div>
            </div>
        </div>
    );
};

export default ModalData;
