import React, { useEffect, useState } from 'react';
import './onlineChat.css';



import { useSelector } from 'react-redux';
import { selectLanguage } from '../home/homeSlice';
import ToggleInstruction from './toggleInstruction';



const Questions = ({ setActiveMenu }) => {

    const language = useSelector(selectLanguage)

    const text1 = `Απάντηση: Η σελίδα δικηγόρος online έχει φτιαχτεί για να σας κάνει τη ζωή πιο εύκολη και στη δυσκολία  να βρίσκετε λύσεις οικονομικά και άμεσα. Έχουμε διάφορες επιλογές στη σελίδα μας για να βρείτε την κατάλληλη λύση για σας. Για πιο άμεση απάντηση λειτουργεί 24/7 το chat bot μας, και αν επιθυμείτε άμεση συνάντηση με τους δικηγόρους μας μπορείτε να επιλέξετε το κουμπί booking online και μέσα στις 24 h εγγυημένα θα έχετε online συνάντηση με ένα από έμπειρους δικηγόρους της πλατφόρμας μας.`

    return (
        <div>
            <div className="services_title_cntr" style={{ marginBottom: '40px'}}>
                {/* <label className='title1_wellcome'>ΥΠΗΡΕΣΙΕΣ</label> */}
                <div className='title1_line' style={{ marginTop: '140px' }}></div>
                <label className='title1_wellcome2'>
                    ΣυχνΕς ΕρωτΗσεις
                </label>
            </div>

            
            <ToggleInstruction text1={'Πως μπορούμε να σας βοηθήσουμε'} text2={text1}/>

            <ToggleInstruction text1={'Πως λειτουργεί το chat bot '} text2={text1}/>

            <ToggleInstruction text1={'Τι κόστος έχει το chat bot '} text2={text1}/>
            <ToggleInstruction text1={'Σε ποιες γλώσσες μιλάει το chat bot και μπορεί να δώσει  από απαντήσεις '} text2={text1}/>
            <ToggleInstruction text1={'Τι κάνω εάν δεν μου απαντάει το chat bot  '} text2={text1}/>
            <ToggleInstruction text1={'Ποσο μπορεί να με βοηθήσει το chat bot  '} text2={text1}/>
            <ToggleInstruction text1={'Ο λόγος της αποστολής στο e-mail username και password μετά τη πληρωμή  '} text2={text1}/>

            



        </div>

    );
};

export default Questions;
