// home.js
import React, { useState } from 'react';
import './onlineChat.css';
import logo_menu from '../../static/img/logo_menu.png';
import { useDispatch, useSelector } from 'react-redux';
import { selectEmail, selectLanguage, setEmail } from '../home/homeSlice';
import { svg_out, svg_burger } from '../../static/svg';
import LanguageSelector from '../home/languageSelector';
import { translations } from '../home/translation';
import Instruction from '../home/instruction';
import Footer from '../home/footer';
import MainBlock from './mainBlock';
import Questions from './questions';

function OnlineChat() {
    const dispatch = useDispatch()
    const email = useSelector(selectEmail);
    const [activeMenu, setActiveMenu] = useState('main');
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const language = useSelector(selectLanguage)

    const handleMenuClick = (menu) => {
        setIsMenuOpen(false);
        if (menu === 'main' && email === '') {
            setActiveMenu('login');
        }
        else {
            setActiveMenu(menu);
        }
    };

    return (

        <div className="home-container">
            <div className='cntr_line'>
                <svg className='svg_line' width="1300" height="1" viewBox="0 0 1300 1" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <line y1="0.5" x2="1300" y2="0.5" stroke="#A0A0A0" />
                </svg>
            </div>
            <div className='cntr_line2'>
                <svg className='svg_line' width="1300" height="1" viewBox="0 0 1300 1" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <line y1="0.5" x2="1300" y2="0.5" stroke="#A0A0A0" />
                </svg>
            </div>

            <nav className="nav-menu" style={{ position: 'absolute', top: "0px", height: '0px', zIndex: 1 }}>
                <div style={{ width: '64px', marginTop: '21px' }}>
                    <img src={logo_menu} alt="Logo" className="logo_menu nav-item" />
                </div>
                <div style={{ marginTop: '41px' }} className='nav-item'>
                    <LanguageSelector />
                </div>

                <div className='cntr_menu'>

                    <span className="nav-item" onClick={() => handleMenuClick('main')}>{translations[language].home}</span>
                    <span className="nav-item" onClick={() => handleMenuClick('we')}>{translations[language].aboutUs}</span>
                    <span className="nav-item" onClick={() => handleMenuClick('we')}>ΥΠΗΡΕΣΙΕΣ</span>

                    <span className="nav-item" onClick={() => handleMenuClick('instructions')}>{translations[language].instructions}</span>
                    <span className="nav-item" onClick={() => { }}>{translations[language].forLawyers}</span>

                </div>

                <div className='nav-item txt_cap_under' style={{marginTop: '31px', marginLeft: '270px'}}>
                    Κράτηση 24/7
                </div>


                {email !== '' &&
                    <div onClick={() => dispatch(setEmail(''))} className='svg_out'>
                        {svg_out}
                    </div>
                }
            </nav>

            <nav className="nav-menu-mobile">
                <div style={{ width: '150px' }}>
                    <img src={logo_menu} alt="Logo" className="logo nav-item" />
                </div>

                <span className="burger-menu" onClick={() => setIsMenuOpen(!isMenuOpen)}>
                    {/* <img style={{ width: '50px', height: '50px', marginTop: '10px', marginRight: '10px' }} src={burger} alt="Burger Menu" /> */}
                    {svg_burger}
                </span>
                <div className='svg_globus'>
                    <LanguageSelector />
                </div>

                {isMenuOpen && (
                    <div className="mobile-menu">
                        <span className="nav-item" onClick={() => handleMenuClick('main')}>{translations[language].home}</span>
                        <span className="nav-item" onClick={() => handleMenuClick('we')}>{translations[language].aboutUs}</span>
                        <span className="nav-item" onClick={() => handleMenuClick('instructions')}>{translations[language].instructions}</span>
                        <span className="nav-item" onClick={() => { }}>{translations[language].forLawyers}</span>
                    </div>
                )}
            </nav>

            {activeMenu === 'main' && <MainBlock handleMenuClick={handleMenuClick} setActiveMenu={setActiveMenu} />}
            
            {/* <Services setActiveMenu={setActiveMenu}/> */}
            {/* <Advantage/> */}
            {/* <Booking/> */}
            {/* <Instruction/> */}
            <Questions/>
            {/* <Comments/> */}
            <Footer handleMenuClick={handleMenuClick}/>
        </div>

    );
}

export default OnlineChat;
