import React, { useState } from 'react';
import main_logo from '../../static/img/logo_main.png'
import './mainPage.css'
import { useSelector } from 'react-redux';
import { selectEmail, selectLanguage, selectStatus } from '../home/homeSlice';
import { getPayLink } from '../profile/apiProfile';
import ModalData from './modalData';
import { sendRegister } from '../login/apiLogin';
import { translations } from '../home/translation';
import BtnChat from '../buttons/btnChat';

const MainPage = ({ handleMenuClick, setActiveMenu }) => {

    const email = useSelector(selectEmail)
    const status = useSelector(selectStatus)
    const language = useSelector(selectLanguage)
    const [showModal, setShowModal] = useState(false);

    const handleBuySubscription = async () => {
        // Logic for buying subscription, this is just a placeholder
        const paylink = await getPayLink(email)
        console.log('paylink', paylink)
        window.open(paylink.paymentLink, '_blank', 'noopener,noreferrer');
    };

    const handleOnlineClick = () => {
        console.log('handleOnlineClick', email, status)
        if (email === '') setActiveMenu('login')
        else if (status !== 'subscribe') handleBuySubscription()
        else handleMenuClick('ai')
    }

    const handleClickBooking = () => {
        setShowModal(true);
    };

    const handleModalClose = () => {
        setShowModal(false);
    };

    const handleRegisterAndBuy = async (email, phone, name, surname) => {
        const result = await sendRegister(email, phone, name, surname);
        if (result) {
            handleBuySubscription();
        }
    };

    return (
        <div className="main-container">            

            {/* <div className='bg_1' style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}></div> */}
            <div className="left-block">
                <label className='main_text' style={{ color: '#FFF' }}>{translations[language].mainText}</label>
                {/* <br /> */}
                <label className='main_subtext'>{translations[language].mainSubtext}</label>

                <div style={{ display: 'flex', gap: '20px', marginTop: '50px' }}>
                    <BtnChat/>
                    <div className="btn_booling_txt btn_booling" onClick={handleClickBooking}>
                        {translations[language].booking24}
                    </div>
                </div>
            </div>
            {/* <div className="right-block">
                <div className="btn_online_txt btn_online" onClick={handleOnlineClick}>
                    {translations[language].onlineLawyerChat}
                </div>
                <div className="button_main button-filled" onClick={handleClickBooking}>
                    {translations[language].booking24}
                </div>
            </div> */}


            <ModalData
                show={showModal}
                handleClose={handleModalClose}
                handleSubmit={handleRegisterAndBuy}
            />



        </div>

    );
}

export default MainPage;


