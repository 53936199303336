import React, { useState } from 'react';
import './wellCome.css';
import wellcome_img from '../../static/img/wellcome.png';

const testimonials = [
    {
        text: 'This is an amazing product!',
        name: 'John Doe',
        avatar: 'https://via.placeholder.com/50'
    },
    {
        text: 'I had a great experience with this service.',
        name: 'Jane Smith',
        avatar: 'https://via.placeholder.com/50'
    },
    {
        text: 'Highly recommend to everyone!',
        name: 'Alice Johnson',
        avatar: 'https://via.placeholder.com/50'
    }
];

const FeedBack = () => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [direction, setDirection] = useState('');

    const prevSlide = () => {
        setDirection('left');
        const index = currentIndex === 0 ? testimonials.length - 1 : currentIndex - 1;
        setCurrentIndex(index);
    };

    const nextSlide = () => {
        setDirection('right');
        const index = currentIndex === testimonials.length - 1 ? 0 : currentIndex + 1;
        setCurrentIndex(index);
    };

    return (
        <div className="feedback-container">
            <div className="feedback-carousel">
                <label className='title1_wellcome'>ΠΟΙΟΙ ΕΙΜΑΣΤΕ</label>
                <div className='title1_line'></div>
                <label className='title1_wellcome2'>
                    ΚαλΩς Ηρθατε στην DIKIGOROS ONLINE
                </label>
                <div style={{ marginTop: '30px'}} className='text_wellcome'>
                Στην DIKIGOROS ONLINE, φέρνουμε την επανάσταση στην παροχή νομικών υπηρεσιών, προσφέροντας εύκολη και άμεση πρόσβαση σε εξειδικευμένους δικηγόρους μέσω διαδικτύου. Είμαστε αφοσιωμένοι στο να παρέχουμε στους πολίτες της χώρας μας αξιόπιστη νομική υποστήριξη, οποιαδήποτε στιγμή την χρειαστούν.<br/><br/>

Προσφέρουμε την άμεση και τεκμηριωμένη απάντηση που ζητάτε, ακόμη και πριν αναθεσετε την ΥΠΟΘΕΣΗ σε ένα απο τους εξειδικευμένους δικηγόρους μας, μέσω της καινοτόμου διαδικτυακής υπηρεσίας μας Chatbot.<br/><br/>

Μην αφήνετε τα νομικά σας προβλήματα να σας ταλαιπωρούν. Επικοινωνήστε μαζί μας σήμερα και επιτρέψτε μας να σας βοηθήσουμε να βρείτε τη λύση που χρειάζεστε. Είμαστε εδώ για εσάς, κάθε στιγμή της ημέρας, κάθε ημέρα της εβδομάδας.<br/>
DIKIGOROS ONLINE - Νομικές Υπηρεσίες Παντού και Πάντα.
                </div>
            </div>
            <div className="feedback-controls">
                <img className='img_wellcome' src={wellcome_img} alt='Wellcome!'/>
                <div className='wellcome_square'></div>
            </div>
        </div>
    );
};

export default FeedBack;
